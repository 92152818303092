import {FormObject} from '@components/forms/form.t'
import {PanelsKeys} from '@libs/panels/panels.t'
import {useCreateMutation, useGetQuery, useErpDomainsByKeyQuery} from '@queries'
import {ErpDomain, FormDomain} from '@shared/interfaces'
import {AppContext, withApp} from '@store/app'
import React, {useMemo} from 'react'
import FormPanel from './form'

export interface FormAddPanelProps extends AppContext {
  formId?: string
  formKey?: string
  domain: string | ErpDomain
  panelKey: PanelsKeys
  data?: FormObject
  onSave?: (values: FormObject) => void
}

const isErpDomain = (domain: string | ErpDomain): domain is ErpDomain => {
  if (typeof domain === 'string') return false
  return domain.key !== undefined
}

const useDomainQuery = (domain: string | ErpDomain) => {
  return useErpDomainsByKeyQuery(isErpDomain(domain) ? domain.key : domain)
}

const FormAddPanel: React.FC<FormAddPanelProps> = ({
  formId,
  formKey,
  domain,
  stateManager,
  onSave,
  ...props
}) => {
  const {t, panelKey, Panels, toast, data} = props

  const createMutation = useCreateMutation()

  const formsQuery = useGetQuery<FormDomain>('forms', {api: {hydrate: true, limit: 0}})

  // when it's a custom tab, the domain is a string and not an ErpDomain
  const domainQuery = useDomainQuery(domain)

  const usedForm = useMemo(() => {
    if (formId) return formId
    if (formKey && formsQuery.data) {
      return formsQuery.data.find((f) => f.key === formKey)?._id
    }
    if (domainQuery.data) {
      return domainQuery.data.forms?.create || domainQuery.data.forms?.read
    }
  }, [formId, formKey, formsQuery.data, domainQuery.data])

  if (!domainQuery.data) return null

  return (
    <FormPanel
      {...props}
      data={{...data, __type: domainQuery.data.key}}
      formId={usedForm}
      onSaveLabel={t('action-bar.create.domain.save', {domain: domainQuery.data}) || undefined}
      onSave={(values) => {
        return createMutation
          .mutateAsync(
            {domain: domainQuery.data, item: {...data, ...values}},
            {
              onSuccess: (res) => {
                onSave?.({...values, ...res})
                Panels.close(panelKey)
                toast.success('action-bar.create.domain.success')
                stateManager?.sub.refresh(domainQuery.data.key)
              }
            }
          )
          .catch((err) => {
            const errorMessage =
              err.response?.data?.data?.err ||
              err.response?.data?.data?.message ||
              'action-bar.create.domain.error'
            toast.error(errorMessage)
          })
      }}
      title={t('action-bar.create.domain.title', {domain: domainQuery.data}) || undefined}
    />
  )
}

export default withApp(FormAddPanel)
