import {LoadingOutlined} from '@ant-design/icons'
import {Spin} from 'antd'
import {NotificationInstance} from 'antd/lib/notification/interface'
import {isFunction, uniqueId} from 'lodash'
import {TFunction} from 'next-i18next'
import React from 'react'

export enum ToastType {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  LOADING = 'loading'
}

interface ToastUpdateOptionFunction<T = unknown> {
  render: (params: {data?: T}) => React.ReactNode
}
export type ToastUpdateOption<T = unknown> = string | ToastUpdateOptionFunction<T> | React.ReactNode
export interface ToastPromiseParams {
  pending?: ToastUpdateOption
  success?: ToastUpdateOption
  error?: ToastUpdateOption
}

export interface Toast {
  ok: (text?: string | {[key: string]: unknown}) => void
  success: (text?: string | {[key: string]: unknown}) => void
  err: (text?: string | {[key: string]: unknown}) => void
  error: (text?: string | {[key: string]: unknown}) => void
  warn: (text?: string | {[key: string]: unknown}) => void
  info: (text?: string | {[key: string]: unknown}) => void
  promise: <T = unknown>(promise: Promise<T>, options: ToastPromiseParams) => T
}

const useToast = (notificationApi: NotificationInstance, t: TFunction): Toast => {
  const toaster =
    (defaultText = '', type: ToastType = ToastType.DEFAULT) =>
    (text: string | {[key: string]: unknown} = defaultText) => {
      // if text is an object, get first key value
      const newText: string = typeof text == 'object' ? JSON.stringify(text, null, 2) : t(text)

      let toastFn
      switch (type) {
        case ToastType.SUCCESS:
          toastFn = notificationApi.success
          break
        case ToastType.ERROR:
          toastFn = notificationApi.error
          break
        case ToastType.INFO:
          toastFn = notificationApi.info
          break
        case ToastType.WARNING:
          toastFn = notificationApi.warning
          break
        default:
          toastFn = notificationApi.open
          break
      }

      toastFn({placement: 'bottomRight', message: <span className='pre-line'>{t(newText)}</span>})
    }

  const ok = toaster('toast.defaults.ok')
  const success = toaster('toast.defaults.success', ToastType.SUCCESS)
  const err = toaster('toast.defaults.error', ToastType.ERROR)
  const error = err
  const warn = toaster('toast.defaults.warning', ToastType.WARNING)

  const info = toaster('toast.defaults.info', ToastType.INFO)

  function promise<T = unknown>(promise: Promise<T>, options: ToastPromiseParams) {
    const id = uniqueId()

    function resolver<T>(
      type: ToastType.SUCCESS | ToastType.ERROR | ToastType.LOADING,
      input: ToastUpdateOption<T>,
      result?: T
    ) {
      // Remove the toast if the input has not been provided. This prevents the toast from hanging
      // in the pending state if a success/error toast has not been provided.
      if (input == null) {
        notificationApi.destroy(id)
        return
      }

      let message
      if (typeof input === 'string' || React.isValidElement(input)) message = input
      else message = (input as ToastUpdateOptionFunction<T>).render({data: result})

      switch (type) {
        case ToastType.SUCCESS:
          notificationApi.success({placement: 'bottomRight', message, key: id})
          break
        case ToastType.ERROR:
          notificationApi.error({placement: 'bottomRight', message, key: id})
          break
        case ToastType.LOADING:
          notificationApi.open({
            placement: 'bottomRight',
            message,
            key: id,
            icon: <Spin indicator={<LoadingOutlined spin />} />,
            duration: 0
          })
      }

      return result
    }

    resolver(ToastType.LOADING, options.pending)

    const p = isFunction(promise) ? promise() : promise

    //call the resolvers only when needed
    return p
      .then((result: T) => resolver(ToastType.SUCCESS, options.success, result))
      .catch((err: Error) => resolver(ToastType.ERROR, options.error, err))
  }

  return {
    ok,
    success,
    err,
    error,
    warn,
    info,
    promise
  }
}

export default useToast
